import request from '@/plugins/axios'

// S 订单

// 订单列表其他列表参数
export const apiOtherLists = () => request.get('/visa.order/otherLists')
// 订单列表其他列表参数
export const getCateMark = () => request.get('/visa.order/getCateMark')

// 订单详情
export const orderInfoApi = (params: any) => request.get('/visa.order/getInfo', { params })

// 订单列表
export const apiOrderLists = (params: any) => request.get('/visa.order/lists', { params })


// 新增订单
export const createOrder = (params: any) => request.post('/visa.order/create', { params })

// 保存订单
export const saveOrderApi = (params: any) => request.post('/visa.order/saveOrder', { params })

// 审核
export const updateAudit = (params: any) => request.post('/visa.order/audit', { params })

// 修改
export const editOrderApi = (params: any) => request.post('/visa.order/editOrder', { params })

// 修改图片类型
export const editImgTypeApi = (params: any) => request.post('/visa.order/editImgType', { params })

// 保存图片
export const uploadImg = (params: any) => request.post('/Upload/upload_image', params)
// 保存图片
export const uploadImageEdit = (params: any) => request.post('/Upload/upload_image_edit', params)

// 精修
export const jingxiuImg = (params: any) => request.post('/visa.order/imageClassify', params)

// 图片识别
export const analyzeImage = (params: any) => request.post('/visa.order/analyzeImage', params)
// 重新生成名单表
export const reUsernameUrl = (params: any) => request.post('/visa.order/re_username_url', params)
// 重新生成行程表
export const reScheduleUrl = (params: any) => request.post('/visa.order/re_schedule_url', params)
// 重新推送
export const re_submit_by_one = (params: any) => request.post('/visa.order/re_submit_by_one', params)
// 重新推送
export const re_submit_all = (params: any) => request.post('/visa.order/re_submit_all', params)

// 查询许可证
export const apiGetLincenseList = (params: any) => request.get('/visa.order/getLincenseList', {params})
// 下载
export const apiDownfiles = (params: any) => request.get('/visa.order/downfiles', {params})
// 导出
export const apiExportfiles = (params: any) => request.get('/visa.order/exportOrderForUser', {params})

// 每日签证数据
export const apiGetVisaData = (params: any) => request.get('/visa.order/getStatistics', {params})
// 每月签证数据
export const apiGetVisaDataByMonth = (params: any) => request.get('/visa.order/getStatisticsByMonth', {params})
